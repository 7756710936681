import moment from "moment";

export default function (value) {
  if (!value) {
    return value;
  }

  let dateMoment = moment(value);
  return dateMoment.isValid() ? dateMoment.format("MM/YYYY") : value;
}
